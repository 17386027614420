/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import { styled } from 'styles';
import useAuthContext from 'hooks/useAuthContext';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Input from 'components/Input';
import notify from 'notify';

const STORAGE_KEY = 'loginData';

const localStorage = typeof window === 'undefined' ? undefined : window.localStorage;

const getStorageData = () => {
  const storageItem = localStorage?.getItem(STORAGE_KEY);
  if (!storageItem) return;
  return JSON.parse(storageItem);
};

const LoginPage = () => {
  const defaultValues = getStorageData();
  const [rememberMe, setRememberMe] = useState(Boolean(defaultValues));
  const { login } = useAuthContext();
  const { register, handleSubmit, errors } = useForm({ defaultValues });

  const submitForm = async values => {
    try {
      await login(values);
      if (rememberMe) localStorage?.setItem(STORAGE_KEY, JSON.stringify(values));
      else if (defaultValues) localStorage?.removeItem(STORAGE_KEY);
      navigate('/');
    } catch (err) {
      notify(err.message);
    }
  };

  return (
    <StyledLoginPage>
      <h1 className="title">Welcome back!</h1>
      <form onSubmit={handleSubmit(submitForm)}>
        <Input
          ref={register({
            required: 'Required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'Invalid email address'
            }
          })}
          name="email"
          label="Email"
          placeholder="Enter Email"
          error={errors.email?.message}
        />
        <br />
        <Input
          ref={register({ required: 'Required' })}
          name="password"
          label="Password"
          placeholder="Enter Password"
          type="password"
        />
        <br />
        <Link className="forgot-password" to="/forgot-password">
          Forgot password?
        </Link>
        <br />
        <Checkbox
          className="remember-me"
          label="Remember me"
          checked={rememberMe}
          onChange={e => setRememberMe(e.target.checked)}
        />
        <Button className="button-submit">Login</Button>
      </form>
    </StyledLoginPage>
  );
};

export default LoginPage;

const StyledLoginPage = styled.div`
  /* mobile first */
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 20px 0;
  }

  form {
    .input,
    .button {
      width: 100%;
    }

    .input {
      margin-bottom: 20px;
    }

    .forgot-password {
      display: inline-block;
      font-size: 14px;
      line-height: 30px;
      color: ${props => props.theme.colors.darkCementGray};
      text-decoration: none;
      margin: 4px 0 55px 0;
    }

    .remember-me {
      height: 30px;
      margin-bottom: 32px;
    }

    .button-submit {
      display: block;
      width: 100%;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 30px;
    }

    .info {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 46px;
    }

    form {
      .input {
        width: 300px;
      }

      .forgot-password {
        margin-bottom: 108px;
      }

      .button-submit {
        height: 40px;
        width: 300px;
      }
    }
  }
`;
