import React from 'react';
import AuthLayout from 'components/AuthLayout';
import LoginPage from 'views/LoginPage';

const Login = () => {
  return (
    <AuthLayout>
      <LoginPage />
    </AuthLayout>
  );
};

export default Login;
